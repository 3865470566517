<template>
  <div class="market">
    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.market {
  width: 100%;
  height: 100vh;
  padding-top: 62px;
  background: #0e2138;
  padding: 62px 0 0;
  background: url("../assets/image/test/cangchu.jpg") center center no-repeat;
  background-size: 100% 100%;
}
</style>
